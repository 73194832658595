.view.view__wrapper {
  padding: 0 3.2rem;
  display: flex;
  flex-flow: column nowrap;
}

.view .view__header {
  padding-top: 3.2rem;
}

.view .view__content {
  margin: 2rem 0;
}

.view .view__breadcrumbs {
  margin-bottom: 1rem;
}

.view .view__title {
  margin: 0;
}

.view .view__subtitle {
  font-size: 2rem;
  color: var(--gray2);
}

.view .view__secondary-actions {
  margin-top: 1rem;
  display: flex;
  white-space: nowrap;
}
