.info-banner.wrapper {
  display: flex;
  flex-flow: row nowrap;
  border-top: 4px solid;
  border-radius: 3px;
  padding: 1.5rem;
}

.info-banner.wrapper.single-line {
  box-shadow: var(--p-card-shadow, 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05));
  outline: 0.1rem solid transparent;
  border-radius: 6px;
}

.info-banner.wrapper.full-width {
  padding: 3rem;
}

.info-banner.wrapper.border-none {
  border: none;
}

.info-banner .info-banner__content {
  display: flex;
}

.info-banner.wrapper.single-line .info-banner__title {
  font-weight: normal;
}

.info-banner .info-banner__title {
  align-self: center;
  font-weight: bold;
  font-size: 1.6rem;
}

.info-banner .icon__wrapper {
  padding-right: 2rem;
}

.info-banner .content__wrapper {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.info-banner .content__wrapper .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: auto;
}

.info-banner .content__wrapper .body__wrapper {
  margin: 1rem 0;
}

.info-banner.wrapper.success {
  background: var(--green-background);
  border-color: var(--green1);
}

.info-banner.wrapper.warning {
  background-color: var(--orange-background);
  border-color: var(--orange1);
}

.info-banner.wrapper.info {
  background-color: var(--blue-background-bright);
  border-color: var(--blue2);
}

.info-banner.wrapper.critical {
  background-color: var(--red-background);
  border-color: var(--red1);
}

.info-banner.wrapper.primary {
  background-color: var(--blue-background-dull);
  border-color: var(--blue4);
}

.info-banner.wrapper.default {
  background-color: var(--gray7);
  border-color: var(--gray2);
}

.info-banner.wrapper.white {
  background-color: var(--white);
  border-color: var(--gray2);
}

.info-banner.wrapper.secondary {
  background-color: var(--purple-background);
  border-color: var(--purple);
}
