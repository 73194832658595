.expand-button-wrapper {
  display: flex;
  justify-content: center;
}

.expand-button-wrapper .button-expand {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: flex-end;
  margin: 1rem;
  font-size: 14px;
}
