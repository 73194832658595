.content-panel .content {
  margin: 1rem;
}

.content-panel-wrapper {
  display: flex;
}

.content-panel-wrapper > *:first-child {
  border-right: 1px solid #dfe3e8;
  padding-right: 3rem;
  margin-right: 3rem;
}

.content-panel-wrapper > * {
  flex: 1;
}
