.info-panel-header {
  border-top: 3px solid var(--gray3);
  display: flex;
  align-items: center;
  padding: 2rem;
}

.info-panel-header .title {
  font-weight: bold;
  font-size: 16px;
}

.info-panel-header .button-close {
  margin-left: auto;
}

.info-panel-header .icon {
  margin-right: 1rem;
}
