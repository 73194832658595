.avatar {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: var(--blue2);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar .inner-wrap {
  font-size: 16px;
  font-weight: 500;
  margin-top: 2px;
}

.avatar.button {
  cursor: pointer;
}
