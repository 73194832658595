.notes-tile .inner-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
}

.notes-tile .inner-wrap.truncated {
  height: 100px;
}

.notes-tile .text {
  cursor: pointer;
}

.notes-tile .text.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notes-tile .footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
}

.notes-tile .footer .content {
  margin: 0;
  color: var(--gray2);
}
