.note-indicator {
    height: 7px;
    width: 7px;
    background-color: var(--black);
    border-radius: 100%;
    display: inline-block;
  }
  
  .note-indicator.contact {
      background-color: var(--blue2);
  }

  .note-indicator.customer {
      background-color: var(--blue4);
  }

  .note-indicator.order {
      background-color: var(--green1);
  }

  .note-indicator.transducer {
      background-color: var(--purple2);
  }

  .note-indicator.hidden {
      visibility: hidden;
  }
  