.field-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
}

.field-display .field-label {
  font-weight: 500;
  margin-right: 1rem;
}

.field-display .field-content {
  color: var(--gray2);
}

.field-display .field-content:empty::before {
  content: '-';
}
