.transducer-filter-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.transducer-filter-group {
  max-width: 80rem;
  flex: auto;
}

.transducer-more-columns-section {
  display: flex;
  justify-content: flex-end;
}

.available-transducers-dt th:first-child {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.available-transducer-secondary-footer__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  margin-bottom: 2rem;
}
.available-transducer-secondary-footer__label {
  margin-left: 1rem;
  font-weight: 500;
}

.available-transducer-modal-actions__iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.available-transducer-modal-actions__copy-button {
  color: var(--blue2);
}
.available-transducer-modal-actions__copy-button .Polaris-Button__Text {
  color: var(--black);
}

.available-transducer-modal-actions__buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0rem;
}
.available-modal-text-area {
  width: '100%';
  border: 1px solid #fafafa;
  height: 50rem;
}
