.available-transducer-filter-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .available-transducer-filter-group {
    max-width: 80rem;
    flex: auto;
  }
  
  .available-transducer-more-columns-section {
    display: flex;
    justify-content: flex-end;
  }

  .transducer-avlb-modal-content{
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin:2rem; 
  }
  .transducer-available-checkbox-column{
    padding: 2rem;
  }