.badge {
  background: #dfe3e8;
  color: #0b2265;
  text-align: center;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
  border-radius: 8px;
  padding: 1px 5px;
  font-weight: 500;
  line-height: 1.4;
}
