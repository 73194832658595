.pagination__wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pagination__wrap .pagination__section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pagination__wrap .pagination__section .summary {
  margin-right: 2rem;
}

.paginaton-button__wrapper.active .Polaris-Button__Text {
  text-decoration: underline;
  font-weight: bold;
  margin-top: 1px;
}

.pagination__wrap .Polaris-ButtonGroup__Item button:not(:disabled) .Polaris-Button__Text,
.pagination__wrap .Polaris-ButtonGroup__Item button:not(:disabled) svg {
  color: var(--blue5);
  fill: var(--blue5);
}

.pagination__wrap .pagination__section .button-previous {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.pagination__wrap .pagination__section .button-next {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}
