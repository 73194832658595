.button.button__secondary {
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  color: var(--gray2);
}

.button.button__secondary:hover {
  color: var(--gray1);
}

.button.button__secondary > svg {
  margin-right: 0.5rem;
  fill: var(--gray1);
}
