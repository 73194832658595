.code-block__wrapper {
  overflow-y: hidden;
}

pre.code-block {
  white-space: pre-wrap;
  overflow-y: auto;
  max-height: 50rem;
  tab-size: 1;
  line-height: 3rem;
  background-color: var(--gray7);
  margin: 0;
  padding-left: 2rem;
}
