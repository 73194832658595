.tabs > .Polaris-Stack:first-of-type {
  overflow-y: hidden;
}

.tabs-children__wrapper {
  padding-top: 0.1rem;
}

.tabs-children__wrapper > *:first-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 3px solid var(--gray6);
}

.tab {
  min-height: 4.5rem;
  min-width: 15rem;
  display: flex;
  flex: 1;
  color: var(--gray3);
  text-align: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}
.tab:hover {
  cursor: pointer;
  color: var(--blue5);
}

.tab-selected {
  border-top: 4px solid;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  animation-name: tabSelectedAnimation;
  animation-duration: 0.2s;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
}

.tab-selected.info {
  background-color: var(--blue-background-bright);
  border-color: var(--blue2);
}

.tab-selected.primary {
  background-color: var(--blue-background-dull);
  border-color: var(--blue4);
}

.tab-selected.success {
  background: var(--green-background);
  border-color: var(--green1);
}

.tab-selected.secondary {
  background-color: var(--purple-background);
  border-color: var(--purple);
}

.tab-selected span {
  color: var(--blue5);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.tab-selected:hover {
  cursor: default;
}
.tab-panel {
  margin-bottom: 1rem;
  position: relative;
}
.rightOption {
  display: grid;
  justify-content: right;
}

.Polaris-Layout__Section {
  margin-top: 2.5rem;
}

@keyframes tabSelectedAnimation {
  from {
    border-top-width: 2px;
    opacity: 0.6;
  }
  to {
    border-top-width: 4px;
    opacity: 1;
  }
}
