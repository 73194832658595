.nav-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--gray1);
  padding: 0.8rem;
  border-radius: 2px;
}

.nav-link:hover {
  color: var(--blue5);
}

.nav-link:hover > .icon {
  fill: var(--blue5);
}

.nav-link.active {
  background: rgba(182, 195, 234, 0.5);
  color: var(--blue5);
  font-weight: bold;
}

.nav-link .badge {
  margin-left: auto;
}

.nav-link .icon {
  margin-right: 16px;
}

.nav-link .icon.active {
  fill: var(--blue5);
}

.nav-child {
  margin-left: 38px;
}

.nav-child .nav-link {
  color: var(--gray3);
}

.nav-child .nav-link:hover {
  color: var(--blue5);
}

.nav-child .nav-link.active {
  color: var(--blue5);
}
