.text-field__wrap__inverted .Polaris-TextField__Backdrop {
  background: rgba(255, 255, 255, 0.18);
  border: none;
}

.text-field__wrap__inverted .Polaris-TextField .Polaris-TextField__Prefix > svg,
.text-field__wrap__inverted .Polaris-TextField > input,
.text-field__wrap__inverted .Polaris-TextField > input::placeholder,
.text-field__wrap__inverted .Polaris-TextField .Polaris-TextField__Suffix > svg {
  color: var(--white);
  fill: var(--white);
}
