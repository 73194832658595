.bread-crumbs__wrapper {
  display: flex;
}

.bread-crumbs__divider {
  margin: 0 0.5rem;
}

.bread-crumbs__plain {
  margin-top: -2px;
  color: var(--gray2);
}

.crumb__wrapper {
  display: flex;
}
