.loading-indicator__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-indicator__wrapper svg {
  height: 6rem !important;
  width: 6rem !important;
}
