.unsaved-changes-modal__body {
    padding: 5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .unsaved-changes-modal__body .message {
    font-size: 20px;
  }
  