.info-panel .section {
  padding: 2rem;
}

.info-panel .title {
  font-weight: bold;
}

.info-panel .header {
  border-bottom: 1px solid var(--gray3);
}

.info-panel .section:not(:last-of-type) {
  border-bottom: 1px solid var(--gray6);
}

.info-panel .section .content {
  margin-top: 1rem;
}
