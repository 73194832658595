.raised-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.raised-icon > svg {
  border-radius: 100%;
  background: var(--white);
  fill: var(--gray3);
}

.raised-icon.success {
  background: var(--green2);
}
.raised-icon.warning {
  background: var(--orange2);
}
.raised-icon.info {
  background: var(--blue1);
}
.raised-icon.critical {
  background: var(--red2);
}
.raised-icon.primary {
  background: var(--blue1);
}
.raised-icon.default {
  background: var(--gray4);
}
