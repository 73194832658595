.data-table__wrapper .Polaris-DataTable__ScrollContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
}

.data-table__wrapper .Polaris-DataTable__ScrollContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 90, 139, 0.8);
}

.data-table__wrapper:not(.filled) tbody .Polaris-DataTable__Cell--firstColumn {
  position: relative;
  white-space: nowrap;
  height: 4.8rem;
}

.data-table__wrapper:not(.filled) .empty {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 0 4rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.data-table__wrapper .empty-title {
  font-size: 2rem;
}
