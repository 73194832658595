.Polaris-DisplayText.Polaris-DisplayText--sizeLarge {
  font-size: 28px;
  font-weight: 500;
}

/* Page */
.Polaris-Page-Header__ActionMenuWrapper {
  margin-top: 0;
  padding: 0 0.5rem;
}

/* Card */
.Polaris-Card {
  overflow-wrap: break-word;
  word-break: break-word;
}

.Polaris-ActionMenu-MenuAction__IconWrapper .Polaris-Icon > svg {
  fill: var(--gray1);
}

/* TextField */
.Polaris-TextField {
  caret-color: var(--p-interactive);
}

.Polaris-TextField.Polaris-TextField--focus .Polaris-TextField__Backdrop,
.Polaris-TextField.Polaris-TextField--focus,
.Polaris-TextField__Backdrop::after {
  border-color: var(--p-interactive);
}

.Polaris-TextField__Prefix > svg {
  height: 16px;
  width: 16px;
  margin-top: 9px;
  fill: var(--gray1);
}

/* Select */
.Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop {
  background: var(--p-interactive);
  box-shadow: 0 0 0 1px var(--p-interactive), 0 0 0 0 transparent;
}

/* Label */
.Polaris-Labelled__LabelWrapper .Polaris-Label label {
  font-weight: 500;
}

/* Popover */
.Polaris-Popover__Content {
  max-width: none;
  max-height: none;
  height: inherit;
}

/* DatePicker */
.Polaris-DatePicker__Day--inRange,
.Polaris-DatePicker__Day--selected {
  background: rgba(179, 188, 245, 0.15);
  border: 1px solid var(--gray6);
  color: var(--gray1);
}

.Polaris-DatePicker__Day:hover {
  background: rgba(223, 227, 232, 0.3);
  font-weight: bold;
}

.Polaris-DatePicker__Day:focus {
  box-shadow: none;
}

/* DataTable */
.Polaris-DataTable {
  overflow-wrap: normal;
  word-break: normal;
}

.Polaris-DataTable__TableRow:nth-child(odd) {
  background-color: var(--gray7);
}

.Polaris-DataTable__Cell {
  white-space: normal;
}

tbody .Polaris-DataTable__Cell:empty::before {
  content: '-';
}

thead .Polaris-DataTable__Cell--header:empty {
  width: 1px;
}

.Polaris-DataTable__Table .Polaris-DataTable__Heading,
.Polaris-DataTable__Cell--header {
  background-color: var(--white) !important;
  color: var(--black) !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

.Polaris-DataTable__Table .Polaris-DataTable__Heading:hover {
  color: var(--black);
}

.Polaris-DataTable__Table .Polaris-DataTable__Heading .Polaris-DataTable__Icon .Polaris-Icon > svg {
  fill: var(--black);
}

.Polaris-DataTable__Footer {
  color: inherit;
  background: transparent;
  padding: 0;
  margin-top: 2rem;
}

/* Button */
.Polaris-Button.Polaris-Button--iconOnly {
  padding: 0;
}

/* Sheet */
.Polaris-Sheet {
  border-radius: 3px;
}

/* Modal */
.Polaris-Modal-Header {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 2rem 2rem 0.5rem 2rem;
  border-bottom: var(--p-thin-border-subdued, 0.1rem solid var(--p-border-subdued, #dfe3e8));
}

/* Frame */
.Polaris-Frame__TopBar {
  position: fixed;
  z-index: 512;
  top: 0;
  left: 0;
  width: 100%;
  height: 7.2rem;
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 7.2rem;
}

/* Top Bar */
.Polaris-TopBar {
  position: relative;
  display: flex;
  height: 7.2rem;
  background-color: var(--top-bar-background);
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__LogoContainer {
    display: flex;
    flex: 0 0 34rem;
    align-items: center;
    height: 100%;
    padding: 0 0.8rem 0 1.6rem;
    flex-basis: 34rem;
    flex-basis: calc(34rem + constant(safe-area-inset-left));
    flex-basis: calc(34rem + env(safe-area-inset-left));
    padding-left: 1.6rem;
    padding-left: calc(1.6rem + constant(safe-area-inset-left));
    padding-left: calc(1.6rem + env(safe-area-inset-left));
  }
}

.Polaris-TopBar__LogoContainer::after {
  content: 'Lumify Administrative System';
  margin-left: 2rem;
  color: var(--white);
  font-weight: bold;
  font-size: 1.7rem;
}

/* Navigation */
@media (min-width: 48.0625em) {
  .Polaris-Frame--hasTopBar .Polaris-Frame__Navigation:not(.Polaris-Frame__Navigation--newDesignLanguage) {
    top: 7.2rem;
    height: calc(100% - 7.2rem);
  }
}

.Polaris-Navigation__Icon svg {
  fill: var(--gray2);
  color: var(--p-override-transparent, white);
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg {
  fill: var(--blue5);
  color: var(--p-override-transparent, white);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Badge {
    margin-top: 0.5rem;
  }
}

/* Checkbox */
/* RadioButton */
.Polaris-Checkbox__Icon,
.Polaris-OptionList-Checkbox__Icon,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Icon {
  --p-icon-on-interactive: var(--p-interactive);
}

.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Icon svg {
  fill: var(--p-interactive);
}

.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage)
  .Polaris-OptionList-Checkbox__Input:focus
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage)
  .Polaris-OptionList-Checkbox__Input:active
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage)
  .Polaris-OptionList-Checkbox__Input:checked
  + .Polaris-OptionList-Checkbox__Backdrop.Polaris-DisplayText.Polaris-DisplayText--sizeLarge,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
  background: var(--p-interactive);
}

.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage) .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage)
  .Polaris-OptionList-Checkbox__Input:focus
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage)
  .Polaris-OptionList-Checkbox__Input:active
  + .Polaris-OptionList-Checkbox__Backdrop,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop,
.Polaris-RadioButton:not(.Polaris-RadioButton--newDesignLanguage) .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop {
  box-shadow: 0 0 0 1px var(--p-interactive), 0 0 0 0 transparent;
}

/* OptionList */
.Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--select.Polaris-OptionList-Option--focused:hover,
.Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--select.Polaris-OptionList-Option--focused,
.Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--focused,
.Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--focused:hover {
  box-shadow: inset 0.2rem 0 0 var(--p-interactive);
  background: var(--blue-background-dull);
}

.Polaris-OptionList-Option:not(.Polaris-OptionList-Option--newDesignLanguage) .Polaris-OptionList-Option--select {
  background: var(--blue-background-dull);
}

/* Primary Button  */
.Polaris-Button--primary {
  --p-button-color: var(--p-action-primary);
  --p-button-text: var(--p-text-on-primary);
  --p-button-color-hover: var(--p-action-primary-hovered);
  --p-button-color-active: var(--p-action-primary-pressed);
  --p-button-color-depressed: var(--p-action-primary-depressed);
  background: linear-gradient(to bottom, #0089c4 0%, #0b2265 100%);
  border-color: #003947;
  box-shadow: inset 0 1px 0 0 #0089c4, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
  color: white;
}

.Polaris-Button--primary:hover {
  background: linear-gradient(to bottom, #0081b8, #091a4e);
  border-color: #37459a;
  color: white;
  text-decoration: none;
}

.Polaris-Button--primary:focus {
  border-color: #006b99;
  box-shadow: inset 0 1px 0 0 #0096d6, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px #006b99;
}

.Polaris-Button--primary:active {
  background: linear-gradient(to bottom, #005d85, #050f2e);
  border-color: #37459a;
  box-shadow: inset 0 0 0 0 transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 1px 0 #003947;
}

.Polaris-Button:focus {
  border-color: var(--p-interactive);
  box-shadow: 0 0 0 1px var(--p-interactive);
}

.Polaris-Button--primary.Polaris-Button--disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(180deg, #0089c4 0%, #0b2265 100%);
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.06);
  color: white;
}
