.date-picker-filter {
  display: flex;
  flex-flow: column nowrap;
}

.date-picker-filter .button-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
