.view-layout.wrapper {
  display: flex;
  flex-flow: row wrap;
}

.view-layout.wrapper > * {
  flex: 1;
  min-width: 370px;
  max-width: 400px;
}

.view-layout.wrapper > *:first-child {
  flex: 3;
  margin-right: 2rem;
  max-width: 970px;
}
