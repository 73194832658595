@font-face {
  font-family: centrale_sans;
  src: local('CentraleSansXLight'), url(./assets/fonts/CentraleSansXLight.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: centrale_sans;
  src: local('CentraleSansLight'), url(./assets/fonts/CentraleSansLight.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: centrale_sans;
  src: local('CentraleSansRegular'), url(./assets/fonts/CentraleSansRegular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: centrale_sans;
  src: local('CentraleSansMedium'), url(./assets/fonts/CentraleSansMedium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: centrale_sans;
  src: local('CentraleSansBold'), url(./assets/fonts/CentraleSansBold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: centrale_sans;
  src: local('CentraleSansXBold'), url(./assets/fonts/CentraleSansXBold.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --gray1: #252525;
  --gray2: #5d5d5d;
  --gray3: #888888;
  --gray4: #b9b9b9;
  --gray5: #cccccc;
  --gray6: #e0e0e0;
  --gray7: #efefef;
  --blue1: #72b5cc;
  --blue2: #0089c4;
  --blue3: #005a8b;
  --blue4: #0039a6;
  --blue5: #0b2265;
  --blue-background-medium: #94cee6;
  --blue-background-bright: #f0fafe;
  --blue-background-dull: #f4f6f8;
  --highlight: #dae0f4;
  --purple-background: #f1ecf6;
  --purple: #631d76;
  --purple2: #8140d7;
  --teal: #1e9d8b;
  --green1: #27ae60;
  --green2: #6fcf97;
  --green3: #9cbb79;
  --green-background: #f1f9f6;
  --red1: #cd202c;
  --red2: #fea2a8;
  --red-background: #ffebec;
  --orange1: #e98300;
  --orange2: #f1b465;
  --orange-background: #fff7eb;

  /* Polaris Token Colors only partially overridden may need to add more */
  /* https://github.com/Shopify/polaris-react/blob/master/documentation/Color%20system.md */
  --p-background: var(--gray7);
  --p-surface: var(--white);
  --p-interactive: var(--blue3);
  --p-action-primary: var(--blue5);
}

body {
  font-family: centrale_sans, tahoma, arial, helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 500;
  font-size: 28px;
}

a,
.button-expand {
  text-decoration: none;
  color: var(--blue3);
  font-weight: 500;
  cursor: pointer;
}

a:hover,
.button-expand:hover {
  font-weight: bold;
}

button {
  font-family: centrale_sans, tahoma, arial, helvetica, sans-serif;
}

.text-field__label {
  font-weight: 500;
  display: flex;
}

.text-field__label.optional::after {
  content: '(optional)';
  margin-left: 1rem;
  color: var(--orange2);
}

.text-field__label svg {
  margin-left: 0.5rem;
}

.field-set {
  display: flex;
  flex-flow: column nowrap;
}

.field-set > * {
  margin-top: 0.5rem;
}

.table__wrap {
  padding: 2rem;
}
.icon-xducer__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4.8rem;
}
.icon-xducer {
  border: 3px solid #631d76;
  border-radius: 25px;
  box-sizing: border-box;
  background: #f1ecf6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-xducer svg {
  transform: rotate(-90deg);
  -webkit-transform: rotate((-90deg));
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.icon-xducer span {
  align-items: center;
  text-align: right;
  margin-right: 0.8rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  color: #631d76;
  font-weight: bold;
  line-height: 24px;
}
