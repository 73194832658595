.popover__inner-wrap {
  padding: 1.5rem;
}

.applied-filters__wrap {
  display: flex;
  flex-flow: row wrap;
}

.applied-filters__wrap .tag {
  margin: 1.5rem 1rem 0 0;
}
